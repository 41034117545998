import { useCloudApi } from '@/composables/cloudApi'
import type {
  V1alpha1CloudConnection,
  V1alpha1CloudEnvironment,
  V1alpha1Secret,
  V1alpha1PoolMember
} from '@streamnative/cloud-api-client-typescript'
import { promiseTimeout } from '@vueuse/core'
import { getErrorMessage } from '@/utils/apiHelper'
import type { Location } from '@/api/organizations'
import type { PulsarState } from './usePulsarState'
import { CloudConnection, CloudEnvironment } from '@/composables/useRbac'
import { hash, randomString } from '@/utils/index'

let lastOrg: string | undefined = undefined
const { pools, fullPools, managedPools } = usePools()

export const CloudEnvironmentStatusCreating = 'Creating'
export const CloudEnvironmentStatusDeleting = 'Deleting'
export const CloudEnvironmentStatusActive = 'Ready'
export const CloudEnvironmentStatusError = 'Create Failure'
export const CloudEnvironmentStatusDeleteError = 'Delete Failure'
export const cloudEnvironmentStatusList = [
  CloudEnvironmentStatusCreating,
  CloudEnvironmentStatusDeleting,
  CloudEnvironmentStatusActive,
  CloudEnvironmentStatusError,
  CloudEnvironmentStatusDeleteError
] as const
export type CloudEnvironmentStatusType = (typeof cloudEnvironmentStatusList)[number]

export const CloudConnectionStatusCreating = 'Creating'
export const CloudConnectionStatusDeleting = 'Deleting'
export const CloudConnectionStatusActive = 'Ready'
export const CloudConnectionStatusError = 'Create Failure'
export const CloudConnectionStatusDeleteError = 'Delete Failure'
export const cloudConnectionStatusList = [
  CloudConnectionStatusCreating,
  CloudConnectionStatusDeleting,
  CloudConnectionStatusActive,
  CloudConnectionStatusError,
  CloudConnectionStatusDeleteError
] as const
export type CloudConnectionStatusType = (typeof cloudConnectionStatusList)[number]

export interface CloudEnvironment {
  name: string
  namespace: string
  label: string
  cloudConnectionName: string
  status: CloudEnvironmentStatusType
  message: string
  region: string
  zone: string
  percentage: number
  defaultGateway: string
  cloudConnection?: CloudConnection
}

export interface CloudConnection {
  name: string
  namespace: string
  label: string
  type: string
  status: CloudConnectionStatusType
  message: string
  environments?: CloudEnvironment[]
  availableLocations: Record<string, string[]>
}

const cloudEnvironments = ref<CloudEnvironment[]>([])
const cloudConnections = ref<CloudConnection[]>([])
const cloudConnectionsWithEnvironments = computed(() => {
  return cloudConnections.value.map(connection => {
    const envs = cloudEnvironments.value.filter(item => {
      return item.cloudConnectionName === connection.name
    })

    return {
      ...connection,
      environments: envs
    }
  })
})

const availableCloudConnections = computed(() => {
  return cloudConnections.value.filter(({ status }) => status === CloudConnectionStatusActive)
})

const availableCloudEnvironments = computed(() => {
  return cloudEnvironments.value.filter(({ status }) => status === CloudEnvironmentStatusActive)
})

const filteredCloudConnectionByPools = computed(() => {
  return availableCloudConnections.value.filter(conn => {
    return pools.value.find(pool => pool.name === conn.name && pool.namespace === conn.namespace)
  })
})

const filteredCloudEnvironmentsByLocations = computed(() => {
  return availableCloudEnvironments.value.filter(env => {
    return managedPools.value.find((location: Location) => {
      return env.region === location.name
    })
  })
})

const connectionRelatedPoolFn = (connection: string) => {
  if (!connection) {
    return undefined
  }
  const { mustOrganization } = usePulsarState()
  return fullPools.value.find(
    pool => pool.metadata?.namespace === mustOrganization() && pool.metadata?.name === connection
  )
}

const isConnectionByocFn = (connection: string) => {
  return connectionRelatedPoolFn(connection)?.spec?.deploymentType === 'managed'
}

const isConnectionByocProFn = (connection: string) => {
  return connectionRelatedPoolFn(connection)?.spec?.deploymentType === 'managed-pro'
}

export const init = (initialState: PulsarState) => {
  const { organization } = usePulsarState()
  const { isRbacUpdating } = rbacHelper()

  const valueChanged = async ([org, ab]: [string | undefined, boolean | undefined]) => {
    if (!org) {
      cloudConnections.value = []
      cloudEnvironments.value = []
      lastOrg = undefined
      return
    }
    if (ab) {
      return
    }

    if (org !== lastOrg) {
      const { canDescribeCloudConnectionList, canDescribeCloudEnvironmentList } = rbacManager()
      if (canDescribeCloudConnectionList() && canDescribeCloudEnvironmentList()) {
        await getCloudConnectionList()
        await getCloudEnvironmentList()
      }
    }
    lastOrg = org
  }

  watch([organization, isRbacUpdating], valueChanged)

  return valueChanged([initialState.organization, isRbacUpdating.value])
}

const AWSValidRegions = [
  'us-east-2',
  'us-east-1',
  'us-west-1',
  'us-west-2',
  'af-south-1',
  'ap-east-1',
  'ap-south-2',
  'ap-southeast-3',
  'ap-southeast-4',
  'ap-south-1',
  'ap-northeast-3',
  'ap-northeast-2',
  'ap-southeast-1',
  'ap-southeast-2',
  'ap-northeast-1',
  'ca-central-1',
  'ca-west-1',
  'eu-central-1',
  'eu-west-1',
  'eu-west-2',
  'eu-south-1',
  'eu-west-3',
  'eu-south-2',
  'eu-north-1',
  'eu-central-2',
  'il-central-1',
  'me-south-1',
  'me-central-1',
  'sa-east-',
  'eastus'
]
const GCPValidRegions = [
  'us-west1',
  'us-west2',
  'us-west3',
  'us-west4',
  'us-central1',
  'us-east1',
  'us-east4',
  'northamerica-northeast1',
  'southamerica-east1',
  'europe-west2',
  'europe-west1',
  'europe-west4',
  'europe-west6',
  'europe-west3',
  'europe-north1',
  'asia-south1',
  'asia-southeast1',
  'asia-southeast2',
  'asia-east2',
  'asia-east1',
  'asia-northeast1',
  'asia-northeast2',
  'australia-southeast1',
  'asia-northeast3'
]
const AzureValidRegions = [
  'eastus2',
  'southcentralus',
  'westus2',
  'westus3',
  'australiaeast',
  'southeastasia',
  'northeurope',
  'swedencentral',
  'uksouth',
  'westeurope',
  'centralus',
  'southafricanorth',
  'centralindia',
  'eastasia',
  'japaneast',
  'koreacentral',
  'canadacentral',
  'francecentral',
  'germanywestcentral',
  'norwayeast',
  'polandcentral',
  'switzerlandnorth',
  'uaenorth',
  'brazilsouth',
  'centraluseuap',
  'qatarcentral',
  'centralusstage',
  'eastusstage',
  'eastus2stage',
  'northcentralusstage',
  'southcentralusstage',
  'westusstage',
  'westus2stage',
  'asia',
  'asiapacific',
  'australia',
  'brazil',
  'canada',
  'europe',
  'france',
  'germany',
  'global',
  'india',
  'japan',
  'korea',
  'norway',
  'singapore',
  'southafrica',
  'switzerland',
  'uae',
  'uk',
  'unitedstates',
  'unitedstateseuap',
  'eastasiastage',
  'southeastasiastage',
  'brazilus',
  'eastusstg',
  'northcentralus',
  'westus',
  'jioindiawest',
  'eastus2euap',
  'southcentralusstg',
  'westcentralus',
  'southafricawest',
  'australiacentral',
  'australiacentral2',
  'australiasoutheast',
  'japanwest',
  'jioindiacentral',
  'koreasouth',
  'southindia',
  'westindia',
  'canadaeast',
  'francesouth',
  'germanynorth',
  'norwaywest',
  'switzerlandwest',
  'ukwest',
  'uaecentral',
  'brazilsoutheas'
]

const getZoneList = (connectionType: 'aws' | 'gcp' | 'azure', region: string) => {
  if (!region) {
    return []
  }
  if (connectionType === 'aws') {
    return ['a', 'b', 'c'].map(zone => region + zone)
  } else if (connectionType === 'gcp') {
    return ['a', 'b', 'c'].map(zone => region + '-' + zone)
  } else {
    return ['1', '2', '3']
  }
}

const getDefaultNetworkCIDR = (connectionType: 'aws' | 'gcp' | 'azure') => {
  if (connectionType === 'aws') {
    return '10.60.0.0/16'
  } else if (connectionType === 'gcp') {
    return '10.10.0.0/16'
  } else {
    return '10.70.0.0/16'
  }
}

const getDefaultSubnetCIDR = (connectionType: 'azure') => {
  if (connectionType === 'azure') {
    return '10.70.0.0/20'
  }
  return ''
}

const parseCloudConnection = (connection: V1alpha1CloudConnection) => {
  let status = CloudConnectionStatusCreating
  let message = 'Creating'
  const condition = connection.status?.conditions?.find(({ type }) => type === 'Ready')
  if (connection.metadata?.deletionTimestamp) {
    status = CloudConnectionStatusDeleting
  } else if (condition && condition.status === 'True') {
    status = CloudConnectionStatusActive
    message = ''
  } else if (condition && condition.status === 'False') {
    status = CloudConnectionStatusError
    message =
      connection.status?.conditions?.find(
        ({ type, status }) => type !== 'Ready' && status === 'False'
      )?.message ?? ''
  }

  const availableLocations: Record<string, string[]> =
    connection.status?.availableLocations?.reduce((curr, obj) => {
      return {
        ...curr,
        [obj.region]: obj.zones
      }
    }, {} as Record<string, string[]>) || {}

  return {
    name: connection.metadata?.name || '',
    namespace: connection.metadata?.namespace || '',
    label: connection.metadata?.name || '',
    type: connection.spec?.type || '',
    status,
    message,
    availableLocations
  } as CloudConnection
}

const parseCloudEnvironment = (data: V1alpha1CloudEnvironment) => {
  const conditions = data.status?.conditions
  const ready = conditions?.find(({ type }) => type === 'Ready')
  const readyCount =
    conditions?.filter(
      ({ status, type }) => type !== 'Ready' && type !== 'Scheduled' && status === 'True'
    ).length || 0
  const stepCount =
    conditions?.filter(({ type }) => type !== 'Ready' && type !== 'Scheduled').length || 1
  let percentage = Math.floor((readyCount / stepCount) * 100)
  let message = ready?.message || 'Creating'
  let status = CloudEnvironmentStatusCreating
  if (ready?.status === 'True') {
    status = CloudEnvironmentStatusActive
    message = ''
    percentage = 100
  } else if (ready?.status === 'False' && ready?.reason === 'ReconcileError') {
    status = CloudConnectionStatusError
  } else {
    let condition = conditions?.find(
      ({ status, type }) => status === 'False' && type !== 'Scheduled' && type !== 'Ready'
    )
    if (!condition) {
      condition = (conditions || [])
        .reverse()
        .find(({ status, type }) => status === 'True' && type !== 'Scheduled' && type !== 'Ready')
    }
    if (condition && condition.message) {
      if (ready?.reason === CloudEnvironmentStatusCreating) {
        message = 'Deploying ' + condition.message
      }
    }
  }

  const isDeleting = !!data.metadata?.deletionTimestamp
  status = isDeleting ? CloudEnvironmentStatusDeleting : status

  if (isDeleting) {
    let condition = conditions?.find(
      ({ type, status, reason }) =>
        type !== 'Scheduled' &&
        type !== 'Ready' &&
        status === 'False' &&
        reason !== 'DeletionCompleted'
    )
    if (!condition) {
      condition = (conditions || [])
        .reverse()
        .find(
          ({ type, status, reason }) =>
            type !== 'Scheduled' &&
            type !== 'Ready' &&
            status === 'False' &&
            reason === 'DeletionCompleted'
        )
    }
    if (condition?.reason === 'ReconcileError') {
      status = CloudEnvironmentStatusDeleteError
      message = condition.message
    } else if (condition?.message) {
      message = 'Deleting ' + condition.message
    }
  }

  const defaultGateway = data.status?.defaultGateway?.privateServiceIds
    ?.map(item => item.id)
    .join(',')

  const cloudConnection = cloudConnections?.value?.find(
    item => item.name === data.spec?.cloudConnectionName
  )

  return {
    name: data.metadata?.name || '',
    namespace: data.metadata?.namespace || '',
    label: data.metadata?.name || '',
    cloudConnectionName: data.spec?.cloudConnectionName || '',
    region: data.spec?.region || '',
    zone: data.spec?.zone || '',
    status,
    message,
    percentage,
    defaultGateway,
    cloudConnection
  } as CloudEnvironment
}

const getCloudConnectionList = async () => {
  const { mustOrganization } = usePulsarState()
  const { data } = await useCloudApi().listNamespacedCloudConnection(mustOrganization())
  cloudConnections.value = data.items.map(connection => {
    return {
      ...parseCloudConnection(connection)
    }
  })
  return cloudConnections
}

const getCloudConnection = async (name: string) => {
  const { mustOrganization } = usePulsarState()
  const { data } = await useCloudApi().readNamespacedCloudConnection(name, mustOrganization())
  return parseCloudConnection(data)
}

const getCloudConnectionWithRetry = async (
  name: string,
  retry: number,
  delay: number
): Promise<{ name?: string; type?: string; status: string; message: string }> => {
  const { mustOrganization } = usePulsarState()
  const { data } = await useCloudApi().readNamespacedCloudConnection(name, mustOrganization())
  const connection = parseCloudConnection(data)
  if (connection.status === CloudConnectionStatusCreating && retry > 0) {
    await promiseTimeout(delay)
    return getCloudConnectionWithRetry(name, retry - 1, delay)
  }
  return connection
}

const createCloudConnection = async ({
  name,
  type,
  accountId,
  projectId,
  subscriptionId,
  tenantId,
  clientId,
  supportClientId
}: {
  name: string
  type: 'aws' | 'gcp' | 'azure'
  accountId?: string
  projectId?: string
  subscriptionId?: string
  tenantId?: string
  clientId?: string
  supportClientId?: string
}) => {
  const body: V1alpha1CloudConnection = {
    kind: 'CloudConnection',
    metadata: {
      name
    }
  }
  if (type === 'aws' && accountId) {
    body.spec = {
      type,
      aws: {
        accountId
      }
    }
  } else if (type === 'gcp' && projectId) {
    body.spec = {
      type,
      gcp: {
        projectId
      }
    }
  } else if (type === 'azure' && subscriptionId && tenantId && clientId && supportClientId) {
    body.spec = {
      type,
      azure: {
        subscriptionId,
        tenantId,
        clientId,
        supportClientId
      }
    }
  }
  const { mustOrganization } = usePulsarState()
  return await useCloudApi().createNamespacedCloudConnection(mustOrganization(), body)
}

const deleteCloudConnection = async (name: string) => {
  const { mustOrganization } = usePulsarState()
  const environment = await useCloudApi().deleteNamespacedCloudConnection(name, mustOrganization())
  return environment
}

const getCloudEnvironment = async (name: string) => {
  const { mustOrganization } = usePulsarState()
  const { data } = await useCloudApi().readNamespacedCloudEnvironment(name, mustOrganization())
  return parseCloudEnvironment(data)
}

const deleteCloudEnvironment = async (name: string) => {
  const { mustOrganization } = usePulsarState()
  const environment = await useCloudApi().deleteNamespacedCloudEnvironment(name, mustOrganization())
  return environment
}

const getCloudEnvironmentList = async () => {
  const { mustOrganization } = usePulsarState()
  const { data } = await useCloudApi().listNamespacedCloudEnvironment(mustOrganization())
  cloudEnvironments.value = data.items.map(item => {
    return parseCloudEnvironment(item)
  })
  return cloudEnvironments
}

const getDefaultBuckets = async () => {
  const { mustOrganization } = usePulsarState()
  if (mustOrganization() === undefined) {
    throw Error('Organization is not defined')
  }
  const { data } = await useCloudApi().listNamespacedPoolMember(mustOrganization())
  const bucketMap = new Map()
  data.items.forEach((item: V1alpha1PoolMember) => {
    if (item.spec?.tieredStorage?.bucketName) {
      bucketMap.set(item.metadata?.name, item.spec?.tieredStorage?.bucketName)
    }
  })
  return bucketMap
}

const createCloudEnvironment = async ({
  connectionName,
  region,
  networkCIDR,
  subnetCIDR,
  environmentAnnotation,
  zonal,
  zone,
  defaultGateway,
  networkId,
  dnsId,
  dnsName,
  metricsRemoteWrite
}: {
  connectionName: string
  connectionType?: string
  region: string
  networkCIDR: string
  subnetCIDR: string
  environmentAnnotation: string
  zonal: boolean
  zone: string
  networkId?: string
  dnsId?: string
  dnsName?: string
  defaultGateway: {
    access: string
    privateService: {
      allowedIds: string
    }
  }
  metricsRemoteWrite?: {
    enabled: boolean
    authType: string
    url: string
    username?: string
    password?: string
    token?: string
  }
}) => {
  try {
    const { mustOrganization } = usePulsarState()
    let secretName = ''
    if (metricsRemoteWrite?.enabled) {
      secretName = `remote-write-${hash(metricsRemoteWrite.url)}-${randomString(8)}`
      const body: V1alpha1Secret = {
        data:
          metricsRemoteWrite.authType === 'basic'
            ? {
                username: metricsRemoteWrite.username as string,
                password: metricsRemoteWrite.password as string
              }
            : {
                token: metricsRemoteWrite.token as string
              },
        metadata: {
          name: secretName,
          namespace: mustOrganization()
        }
      }
      await useCloudApi().createNamespacedSecret(mustOrganization(), body)
    }

    const body: V1alpha1CloudEnvironment = {
      kind: 'CloudEnvironment',
      metadata: {
        annotations: {
          'cloud.streamnative.io/environment-type': environmentAnnotation
        }
      },
      spec: {
        cloudConnectionName: connectionName,
        region,
        network: {
          cidr: networkCIDR,
          subnetCIDR
        },
        defaultGateway: {
          access: defaultGateway.access
        }
      }
    }
    if (metricsRemoteWrite?.enabled) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      body.spec.remoteWrites = [
        {
          protocol: 'prometheus',
          url: metricsRemoteWrite.url,
          auth: {
            type: metricsRemoteWrite.authType,
            secretRef: {
              name: secretName,
              namespace: mustOrganization()
            }
          }
        }
      ]
    }
    if (zonal && body.spec) {
      body.spec.zone = zone
    }
    if (networkId && body.spec) {
      body.spec.network = {
        id: networkId
      }
    }
    if (dnsId && dnsName && body.spec) {
      // eslint-disable-next-line
      ;(body.spec as any).dns = {
        id: dnsId,
        name: dnsName
      }
    }
    if (defaultGateway.access === 'private' && body.spec?.defaultGateway) {
      body.spec.defaultGateway.privateService = {
        allowedIds: defaultGateway.privateService.allowedIds.split(',')
      }
    }
    return await useCloudApi().createNamespacedCloudEnvironment(mustOrganization(), body)
  } catch (e) {
    console.log(e)
    throw Error(getErrorMessage(e))
  }
}

export const useEnvironment = () => {
  return {
    CloudEnvironment,
    CloudEnvironmentStatusActive,
    CloudEnvironmentStatusCreating,
    CloudEnvironmentStatusDeleting,
    CloudEnvironmentStatusError,
    CloudEnvironmentStatusDeleteError,
    CloudConnection,
    CloudConnectionStatusActive,
    CloudConnectionStatusCreating,
    CloudConnectionStatusDeleting,
    CloudConnectionStatusError,
    CloudConnectionStatusDeleteError,
    init,
    AWSValidRegions,
    GCPValidRegions,
    AzureValidRegions,
    cloudConnections,
    cloudEnvironments,
    availableCloudConnections,
    availableCloudEnvironments,
    cloudConnectionsWithEnvironments,
    filteredCloudConnectionByPools,
    filteredCloudEnvironmentsByLocations,
    getCloudConnection,
    getCloudConnectionWithRetry,
    getCloudConnectionList,
    createCloudConnection,
    createCloudEnvironment,
    getCloudEnvironment,
    getCloudEnvironmentList,
    deleteCloudEnvironment,
    deleteCloudConnection,
    getZoneList,
    getDefaultNetworkCIDR,
    getDefaultSubnetCIDR,
    connectionRelatedPoolFn,
    isConnectionByocFn,
    isConnectionByocProFn,
    getDefaultBuckets
  }
}
